import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SongCard from './SongCard'
import SkizaModal from './SkizaModal'
import { getSongList } from '../actions/songsActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useSwipeable } from 'react-swipeable'

function SongList() {
  const [skizaModalVisible, setSkizaModalVisible] = useState(false)
  const [selectedSong, setSelectedSong] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { songs, metadata, loading } = useSelector((state) => state.songs)

  useEffect(() => {
    dispatch(getSongList(currentPage, searchQuery))
  }, [dispatch, currentPage, searchQuery])

  const handleSelectSkiza = (song) => {
    navigate(`/skiza_tunes/${encodeURIComponent(song.title)}`)
  }

  const handleCloseModal = () => {
    setSelectedSong(null)
    setSkizaModalVisible(false)
    navigate('/skiza_tunes')
  }

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= metadata.last_page) {
      setCurrentPage(pageNumber)
    }
  }

  const handleSearch = () => {
    setCurrentPage(1)
    dispatch(getSongList(1, searchQuery))
  }

  const filteredSongs = songs.filter(
    (song) =>
      (song.artist &&
        song.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (song.productCode &&
        song.productCode.toLowerCase().includes(searchQuery.toLowerCase()))
  )

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentIndex < filteredSongs.length - 1) {
        setCurrentIndex(currentIndex + 1)
      }
    },
    onSwipedRight: () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1)
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  return (
    <div className="bg-black py-10 text-white">
      <div className="container mx-auto px-4">
        {/* Search Bar */}
        <div className="mb-6 mt-10 flex items-center md:mt-0">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full rounded-lg border border-gray-300 px-4 py-2 text-black focus:outline-none focus:ring-2 focus:ring-brandPrimary md:w-80"
          />
          <button
            onClick={handleSearch}
            className="ml-2 flex items-center rounded bg-brandPrimary px-4 py-2 text-white hover:bg-brandSecondary"
          >
            <FontAwesomeIcon icon={faSearch} className="mr-2" /> Search
          </button>
        </div>

        {/* Loader */}
        {loading && <p className="text-center text-gray-400">Loading...</p>}

        {/* Songs Display */}
        {window.innerWidth < 640 ? (
          // Mobile view with swipe
          <div {...swipeHandlers} className="flex overflow-x-auto">
            {filteredSongs.length > 0 ? (
              filteredSongs.map((song, index) => (
                <div
                  key={song.productCode}
                  className={`w-full flex-shrink-0 p-2 md:w-1/3 lg:w-1/4 ${index === currentIndex ? 'block' : 'hidden'}`}
                >
                  <SongCard
                    image={song.imageUrl}
                    title={song.title}
                    artist={song.artist}
                    audioUrl={song.audioUrl}
                    productCode={song.productCode}
                    price={song.price}
                    onSelectSkiza={() => handleSelectSkiza(song)}
                  />
                </div>
              ))
            ) : (
              <p className="col-span-full text-center text-gray-400">
                No songs found.
              </p>
            )}
          </div>
        ) : (
          // Desktop view with grid
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {filteredSongs.length > 0 ? (
              filteredSongs.map((song) => (
                <SongCard
                  key={song.productCode}
                  image={song.imageUrl}
                  title={song.title}
                  artist={song.artist}
                  audioUrl={song.audioUrl}
                  productCode={song.productCode}
                  price={song.price}
                  onSelectSkiza={() => handleSelectSkiza(song)}
                />
              ))
            ) : (
              <p className="col-span-full text-center text-gray-400">
                No songs found.
              </p>
            )}
          </div>
        )}

        {/* Pagination */}
        <div className="mt-6 flex items-center justify-between">
          <button
            onClick={() => handlePageChange(metadata.current_page - 1)}
            disabled={!metadata.has_previous_page}
            className="rounded bg-gray-200 px-4 py-2 text-gray-800 hover:bg-gray-300 disabled:opacity-50"
          >
            Previous
          </button>
          <span className="text-gray-400">
            Page {metadata.current_page} of {metadata.last_page}
          </span>
          <button
            onClick={() => handlePageChange(metadata.current_page + 1)}
            disabled={!metadata.has_next_page}
            className="rounded bg-gray-200 px-4 py-2 text-gray-800 hover:bg-gray-300 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>

      {/* Skiza Modal */}
      {skizaModalVisible && selectedSong && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="w-full max-w-lg">
            <SkizaModal
              songTitle={selectedSong.title}
              artist={selectedSong.artist}
              image={selectedSong.imageUrl}
              price={selectedSong.price}
              onClose={handleCloseModal}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default SongList
