import React from 'react'
import Seo from './SEO'

const Header = () => {
  return (
    <>
      <Seo />

      <header className="fixed left-0 right-0 top-0 z-10 bg-white py-2 text-white shadow-md md:py-4">
        <div className="container mx-auto flex flex-col items-center justify-between md:flex-row">
          <div className="mb-2 flex items-center justify-center md:mb-0 md:justify-start">
            <a href="https://theplaygroup.net">
              <img
                src="https://i0.wp.com/theplaygroup.net/wp-content/uploads/2023/06/play-loader.png?fit=279%2C100&ssl=1"
                alt="Logo"
                className="h-10 md:h-12"
              />
            </a>
          </div>
          {/* <div className="mt-2 text-center md:mt-0 md:text-right">
          {' '}
          <a
            href="https://theplaygroup.net"
            style={{ backgroundColor: '#F28500' }}
            className="rounded-full px-3 py-1 text-white transition-colors duration-300 ease-in-out hover:bg-orange-700 md:px-4 md:py-2"
          >
            Go to Main Page
          </a>
        </div> */}
        </div>
      </header>
    </>
  )
}

export default Header
