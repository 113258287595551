import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import playgroup1 from '../../Assets/Play Skiza Web Banners test.png'
import playgroup2 from '../../Assets/Play Communications Skiza Web Banners 2/Play Skiza Web Banners Ad.png'
import playgroup3 from '../../Assets/Play Communications Skiza Web Banners 2/Play Skiza Web Banners Gin Idea Ad.png'
import playgroup4 from '../../Assets/Play Communications Skiza Web Banners 2/Play Skiza Web Banners Kagwe.png'

const ImageCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div className="carousel hidden md:block">
      <Slider {...settings}>
        <div className="text-center">
          <img src={playgroup2} alt="Image 1" className="mx-auto w-full" />
        </div>
        <div className="text-center">
          <img src={playgroup1} alt="Image 2" className="mx-auto w-full" />
        </div>
        <div className="text-center">
          <img src={playgroup3} alt="Image 3" className="mx-auto w-full" />
        </div>
        <div className="text-center">
          <img src={playgroup4} alt="Image 4" className="mx-auto w-full" />
        </div>
      </Slider>
    </div>
  )
}

export default ImageCarousel
