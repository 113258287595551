import React, { useState } from 'react'

function Footer() {
  const [isFooterVisible, setIsFooterVisible] = useState(false)

  const toggleFooterVisibility = () => {
    setIsFooterVisible(!isFooterVisible)
  }

  return (
    <footer
      id="footer"
      className={`classic underline-effect bg-white text-black ${
        isFooterVisible ? '' : 'md:hidden'
      }`}
    >
      {/* Toggle button for mobile screens */}
      <button
        className="absolute right-0 top-0 px-4 py-2 text-sm text-orange-500 hover:text-orange-700 md:hidden"
        onClick={toggleFooterVisibility}
      >
        {isFooterVisible ? 'Hide' : 'Show'}
      </button>

      <div className="upper-footer py-8">
        <div className="container">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <div className="footer-widget-column text-center md:text-left">
              <h5 className="widget-title text-orange-500 md:pl-32">
                The Play Group
              </h5>
              <p className="py-4 text-sm md:pl-32">
                Play is a leading Pan-African company delivering world-class
                solutions for mobile subscribers globally.
              </p>
            </div>
            <div className="footer-widget-column text-center md:text-left">
              <h5 className="widget-title py-2">
                <span className="text-orange-500">Links</span>
              </h5>
              <ul className="list-none">
                <li className="py-2 text-sm md:pl-32">
                  <a
                    href="https://theplaygroup.net/"
                    className="hover:text-orange-500"
                    aria-current="page"
                  >
                    Home
                  </a>
                </li>
                <li className="py-2 text-sm md:pl-32">
                  <a
                    href="https://theplaygroup.net/about-us/"
                    className="hover:text-orange-500"
                  >
                    About Us
                  </a>
                </li>
                <li className="py-2 text-sm md:pl-32">
                  <a
                    href="https://theplaygroup.net/contact-us/"
                    className="hover:text-orange-500"
                  >
                    Contact Us
                  </a>
                </li>
                <li className="py-2 text-sm md:pl-32">
                  <a
                    href="https://main.d29kj1s6edbm7b.amplifyapp.com/"
                    className="hover:text-orange-500"
                  >
                    Registration Desk
                  </a>
                </li>
              </ul>
            </div>
            <div className="pl-0 text-center md:pl-16 md:text-left">
              <h5 className="py-2 text-orange-500">Contact Us</h5>
              <p className="text-sm">
                Emilia Apartments,
                <br />
                Gatundu road
                <br />
                Kileleshwa, Nairobi
                <br />
                <a
                  href="tel:+254729699855"
                  className="hover:border-b-2 hover:border-orange-500 hover:text-orange-500"
                >
                  (254) 729 699855
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr className="h-1 border-t border-orange-500 border-opacity-50" />
      <div className="lower-footer bg-white py-3 text-center">
        <div className="container">
          <span className="text-sm">
            &copy; 2023. The Play Group. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
