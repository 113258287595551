import React from 'react'
import { Helmet } from 'react-helmet'

const Seo = () => {
  return (
    <Helmet>
      <title>
        Play SKIZA Tunes Kenya - Subscribe to Your Favorite Artist&apos;s SKIZA Tunes
        with Safaricom
      </title>

      <meta
        name="description"
        content="Discover and subscribe to your favorite artist's SKIZA Tunes effortlessly. Get started with Play SKIZA Tunes Kenya today!"
      />
      <meta name="robots" content="index, follow" />
      <link
        rel="icon"
        href="https://images.template.net/wp-content/uploads/2015/08/Free-Solid-Orange-Background-Downolad.png"
      />

      {/* Open Graph meta tags */}
      <meta
        property="og:title"
        content="Play SKIZA Tunes Kenya - Subscribe to Your Favorite Artist's SKIZA Tunes with Safaricom"
      />
      <meta
        property="og:description"
        content="Discover and subscribe to your favorite artist's SKIZA Tunes effortlessly. Get started with Play SKIZA Tunes Kenya today!"
      />
      <meta
        property="og:image"
        content="https://images.template.net/wp-content/uploads/2015/08/Free-Solid-Orange-Background-Downolad.png"
      />
      <meta property="og:url" content="https://www.skiza.theplaygroup.net/" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Play SKIZA Tunes Kenya" />

      <meta
        name="keywords"
        content="Play SKIZA Tunes, SKIZA TUNES, Safaricom, Subscribe to Artist Ringtones, Music Subscription, SKIZA Tunes Kenya, Play"
      />
      <meta name="author" content="Play SKIZA Tunes Kenya" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="geo.placename" content="Nairobi, Kenya" />
      <meta name="geo.region" content="KE" />
      <meta name="geo.position" content="-1.2921, 36.8219" />
      <meta name="ICBM" content="-1.2921, 36.8219" />
      <meta name="twitter:card" content="summary_large_image" />

      {/* <link rel="canonical" href="https://skiza.theplaygroup.net/" /> */}
      <link rel="canonical" href="https://www.skiza.theplaygroup.net/" />

      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content="Play SKIZA Tunes Kenya" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="msapplication-tap-highlight" content="no" />
      <meta name="theme-color" content="#ffffff" />

      {/* Twitter Card meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Play SKIZA Tunes Kenya - Subscribe to Your Favorite Artist's SKIZA Tunes with Safaricom"
      />
      <meta
        name="twitter:description"
        content="Discover and subscribe to your favorite artist's SKIZA Tunes effortlessly. Get started with Play SKIZA Tunes Kenya today!"
      />
      <meta
        name="twitter:image"
        content="https://images.template.net/wp-content/uploads/2015/08/Free-Solid-Orange-Background-Downolad.png"
      />
      <meta name="twitter:site" content="@CommsPlay" />

      {/* Instagram meta tags */}
      <meta property="og:instagram:card" content="summary_large_image" />
      <meta
        property="og:instagram:title"
        content="Play SKIZA Tunes Kenya - Subscribe to Your Favorite Artist's SKIZA Tunes with Safaricom"
      />
      <meta
        property="og:instagram:description"
        content="Discover and subscribe to your favorite artist's SKIZA Tunes effortlessly. Get started with Play SKIZA Tunes Kenya today!"
      />
      <meta
        property="og:instagram:image"
        content="https://images.template.net/wp-content/uploads/2015/08/Free-Solid-Orange-Background-Downolad.png"
      />

      {/* LinkedIn meta tags */}
      <meta
        property="og:linkedin:title"
        content="Play SKIZA Tunes Kenya - Subscribe to Your Favorite Artist's SKIZA Tunes with Safaricom"
      />
      <meta
        property="og:linkedin:description"
        content="Discover and subscribe to your favorite artist's SKIZA Tunes effortlessly. Get started with Play SKIZA Tunes Kenya today!"
      />
      <meta
        property="og:linkedin:image"
        content="https://images.template.net/wp-content/uploads/2015/08/Free-Solid-Orange-Background-Downolad.png"
      />
      <meta
        property="og:linkedin:url"
        content="https://www.skiza.theplaygroup.net/"
      />

      {/* TikTok meta tags */}
      <meta
        property="og:tiktok:title"
        content="Play SKIZA Tunes Kenya - Subscribe to Your Favorite Artist's SKIZA Tunes with Safaricom"
      />
      <meta
        property="og:tiktok:description"
        content="Discover and subscribe to your favorite artist's SKIZA Tunes effortlessly. Get started with Play SKIZA Tunes Kenya today!"
      />
      <meta
        property="og:tiktok:image"
        content="https://images.template.net/wp-content/uploads/2015/08/Free-Solid-Orange-Background-Downolad.png"
      />
      <meta property="og:tiktok:url" content="https://www.skiza.theplaygroup.net/" />

      {/* Facebook meta tags */}
      <meta
        property="og:facebook:title"
        content="Play SKIZA Tunes Kenya - Subscribe to Your Favorite Artist's SKIZA Tunes with Safaricom"
      />
      <meta
        property="og:facebook:description"
        content="Discover and subscribe to your favorite artist's SKIZA Tunes effortlessly. Get started with Play SKIZA Tunes Kenya today!"
      />
      <meta
        property="og:facebook:image"
        content="https://images.template.net/wp-content/uploads/2015/08/Free-Solid-Orange-Background-Downolad.png"
      />
      <meta
        property="og:facebook:url"
        content="https://www.skiza.theplaygroup.net/"
      />

      <script src="https://cdn.pagesense.io/js/zurikenya/1fe7498f9f12441b881c43fa4820d4c1.js"></script>
    </Helmet>
  )
}

export default Seo
