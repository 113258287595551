import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/LandingPage/Header'
import Footer from './components/LandingPage/Footer'
import ImageCarousel from './components/LandingPage/ImageCarousel'
import SkizaPage from './Pages/SkizaPage' // Import SkizaPage

function App() {
  return (
    <div>
      <ToastContainer position="top-center" />
      <Header />
      <ImageCarousel />
      <Routes>
        <Route path="/skiza_tunes" element={<SkizaPage />} />
        <Route path="/skiza_tunes/:songTitle" element={<SkizaPage />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App
