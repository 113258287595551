import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { subscribeSkiza } from '../actions/songsActions'

function SkizaModal({
  songTitle,
  artist,
  image,
  price,
  productCode,
  phoneNumber,
  onClose,
}) {
  const dispatch = useDispatch()

  const handleSubscribe = () => {
    const subscriptionData = {
      songTitle,
      artist,
      price,
      productCode,
      phoneNumber, // Add phoneNumber to subscriptionData
    }

    dispatch(subscribeSkiza(subscriptionData))
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60">
      <div className="relative w-full max-w-md overflow-hidden rounded-lg bg-white shadow-lg">
        <img
          src={image}
          alt={songTitle}
          className="h-80 w-full object-cover"
          style={{ height: '32rem', backgroundImage: `url(${image})` }}
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-gradient-to-t from-black to-transparent p-4">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-white hover:text-gray-300"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="mt-4 text-center text-white">
            <h2 className="mb-2 text-4xl font-bold">{songTitle}</h2>
            <p className="mb-2 text-xl">{artist}</p>
            <p className="text-sm">{price} KES / daily</p>
            <button
              onClick={handleSubscribe}
              className="mt-4 rounded-lg bg-brandPrimary px-6 py-3 text-white"
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

SkizaModal.propTypes = {
  songTitle: PropTypes.string.isRequired,
  artist: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  productCode: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SkizaModal
