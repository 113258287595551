// actions/skizaActions.js
import axios from 'axios'
import {
  SUBSCRIBE_SKIZA_REQUEST,
  SUBSCRIBE_SKIZA_SUCCESS,
  SUBSCRIBE_SKIZA_FAILURE,
  GET_SONG_LIST_REQUEST,
  GET_SONG_LIST_SUCCESS,
  GET_SONG_LIST_FAILURE,
} from '../constants/songs'
import { API_URL } from '../config/config'
import { toast } from 'react-toastify'

// Subscribe to Skiza
export const subscribeSkiza = (subscriptionData) => async (dispatch) => {
  dispatch({ type: SUBSCRIBE_SKIZA_REQUEST })
  try {
    const res = await axios.post(`${API_URL}/subscribe_skiza`, subscriptionData)
    dispatch({
      type: SUBSCRIBE_SKIZA_SUCCESS,
      payload: res.data,
    })
    toast.success('Subscription successful')
  } catch (error) {
    dispatch({
      type: SUBSCRIBE_SKIZA_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to subscribe')
  }
}

export const getSongList =
  ({ page = 1, size = 10 } = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_SONG_LIST_REQUEST })
    try {
      const { data } = await axios.get(`${API_URL}/songlist`, {
        params: { page, size },
      })
      // console.log('API Response Data:', data)
      dispatch({
        type: GET_SONG_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_SONG_LIST_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
