// import React from 'react'
// import ReactDOM from 'react-dom/client'
// import './index.css'
// import App from './App'
// import { Helmet } from 'react-helmet'

// const root = ReactDOM.createRoot(document.getElementById('root'))
// root.render(
//   <React.StrictMode>
//     <Helmet>
//       <title>Play Skiza Tunes</title>
//       <meta name="description" content="Welcome to Play Skiza Tunes" />
//       <link
//         rel="icon"
//         href="https://images.template.net/wp-content/uploads/2015/08/Free-Solid-Orange-Background-Downolad.png"
//       />
//     </Helmet>
//     <App />
//   </React.StrictMode>
// )

import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './store'
import './App.css'
import './index.css'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
)
