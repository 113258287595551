// reducers/skizaReducer.js

import {
  SUBSCRIBE_SKIZA_REQUEST,
  SUBSCRIBE_SKIZA_SUCCESS,
  SUBSCRIBE_SKIZA_FAILURE,
  GET_SONG_LIST_REQUEST,
  GET_SONG_LIST_SUCCESS,
  GET_SONG_LIST_FAILURE,
} from '../constants/songs'
import { toast } from 'react-toastify'

const initialState = {
  subscription: null,
  songs: [],
  metadata: {},
  loading: false,
  errors: {},
}

export default function skizaReducer(state = initialState, action) {
  switch (action.type) {
    case SUBSCRIBE_SKIZA_REQUEST:
    case GET_SONG_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case SUBSCRIBE_SKIZA_SUCCESS:
      return {
        ...state,
        subscription: action.payload,
        loading: false,
      }
    case GET_SONG_LIST_SUCCESS:
      // console.log()
      return {
        ...state,
        songs: action.payload.data,
        metadata: action.payload.metadata,
        loading: false,
      }
    case SUBSCRIBE_SKIZA_FAILURE:
      toast.error(`Failed to subscribe: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case GET_SONG_LIST_FAILURE:
      toast.error(`Failed to fetch song list: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
