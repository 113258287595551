import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SongList from '../components/SongList'
import SkizaModal from '../components/SkizaModal'
import { useSelector } from 'react-redux'

function SkizaPage() {
  const { songTitle } = useParams()
  const [selectedSong, setSelectedSong] = useState(null)
  const songs = useSelector((state) => state.songs.songs)

  useEffect(() => {
    if (songTitle) {
      const song = songs.find((song) => song.title === decodeURIComponent(songTitle))
      setSelectedSong(song)
    }
  }, [songTitle, songs])

  const handleCloseModal = () => {
    setSelectedSong(null)
  }

  return (
    <div>
      <SongList />
      {selectedSong && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="w-full max-w-lg">
            <SkizaModal
              songTitle={selectedSong.title}
              artist={selectedSong.artist}
              image={selectedSong.imageUrl}
              price={selectedSong.price}
              productCode={selectedSong.productCode}
              phoneNumber="" // Provide default or fetched phone number
              onClose={handleCloseModal}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default SkizaPage
