import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function SongCard({
  image,
  title,
  artist,
  audioUrl,
  productCode,
  price,
  onSelectSkiza,
}) {
  const [hovered, setHovered] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const audioElement = new Audio(audioUrl)

  useEffect(() => {
    return () => {
      audioElement.pause()
      audioElement.currentTime = 0
    }
  }, [audioElement])

  const handlePlayPause = () => {
    if (isPlaying) {
      audioElement.pause()
    } else {
      audioElement.play()
    }
    setIsPlaying(!isPlaying)
  }

  return (
    <div
      className={`relative cursor-pointer overflow-hidden rounded-xl shadow-lg transition duration-300 ease-in-out ${hovered ? 'scale-105 shadow-xl' : ''}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => onSelectSkiza({ title, artist, image, price, productCode })}
    >
      <div
        className="w-full bg-cover bg-center"
        style={{ height: '32rem', backgroundImage: `url(${image})` }}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-center p-4 text-center">
          <div className="mb-4">
            <h3 className="mb-1 text-xl font-bold text-white md:text-2xl">
              {title}
            </h3>
            <p className="text-sm text-gray-200 md:text-base">{artist}</p>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation()
              handlePlayPause()
            }}
            className="rounded-full bg-orange-500 p-2 text-white hover:bg-orange-600 focus:outline-none"
          >
            {isPlaying ? (
              <svg
                className="h-6 w-6 md:h-8 md:w-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 6h2v12H9zm4 0h2v12h-2z"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 rotate-180 transform md:h-8 md:w-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 12l14 9V3l-14 9z"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

SongCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  artist: PropTypes.string.isRequired,
  audioUrl: PropTypes.string.isRequired,
  productCode: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  onSelectSkiza: PropTypes.func.isRequired,
}

export default SongCard
